import React from "react";
import { Link } from "gatsby";

import styles from "./BlogCard.module.scss";

import Image from "../Image";

function blogCard({ post }) {
  return (
    <Link to={post.fields.uri}>
      <div className={styles.blogCard}>
        <div className={styles.blogCardImage}>
          <Image
            cloudinaryImage={post.frontmatter.cloudinary_image}
            className={styles.mainImage}
          />
        </div>
        <div className={styles.blogCardContent}>
          <p>
            <small>{post.frontmatter.date}</small>
            <br />
            {post.frontmatter.title}
          </p>
          <p />
        </div>
      </div>
    </Link>
  );
}

export default blogCard;
